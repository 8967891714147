require('./bootstrap');

function toggleTopButton() {

    if ($(this).scrollTop() > 20) {
        $("#goTop").fadeIn("fast");
    } else {
        $("#goTop").fadeOut("fast");
    }
}

$(function () {
    toggleTopButton();
    $('#goTop').on('click', function (event) {
        event.preventDefault();
        $('html, body').animate({scrollTop: 0}, 'slow');
    });
    window.onscroll = function () {
        toggleTopButton();
    };
});
